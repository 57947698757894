<template>
  <!-- 主体 -->
  <div class="login-mian">
    <!-- 头部logo -->
    <div class="login-mian-head">
      <img src="../../assets/images/logo.png" alt />
      <btn-i18n></btn-i18n>
    </div>
    <!-- 主体 -->
    <div class="login-mian-body">
      <!-- 登录form框 -->
      <div class="body-form">
        <div class="body-form-mian">
          <!-- 字体提示 -->
          <div class="body-form-mian-item">
            <p>{{$t('欢迎，请登录')}}!</p>
            <div class="form-ipunt" style="margin-top: 64px;">
              <el-input v-model="formdata.username" :placeholder="$t('请输入账户')">
                <i
                  slot="prefix"
                  style="height: 2.875rem; display: flex;flex-flow: row nowrap;justify-content: center;align-items: center;"
                >
                  <img
                    style="width:1.5rem;height:1.5rem"
                    src="@/assets/images/login/iconcharacter.png"
                    alt
                  />
                </i>
              </el-input>
            </div>
            <div class="form-ipunt" style="margin-top: 24px;">
              <el-input v-model="formdata.password" type="password" :placeholder="$t('请输入密码')">
                <i
                  slot="prefix"
                  style="height: 2.875rem; display: flex;flex-flow: row nowrap;justify-content: center;align-items: center;"
                >
                  <img
                    style="width:1.5rem;height:1.5rem"
                    src="@/assets/images/login/password.png"
                    alt
                  />
                </i>
              </el-input>
            </div>
            <el-button
              class="form-but"
              type="primary"
              :loading="loading"
              @click="login"
            >{{$t('登录')}}</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="footer">
      {{$t('版权归海峡经济研究院所有')}}
      {{$t('备案号')}}：
      {{$t('闽ICP备2021008848号')}}
    </div>
  </div>
</template>

<script>
import btnI18n from '../../components/btnI18n'
export default {
  components: { btnI18n },
  data() {
    return {
      input1: '',
      input2: '',
      formdata: {
        username: '',
        password: '',
      },
      loading: false,
    }
  },
  methods: {
    login() {
      this.loading = true
      // 我们这样进行封装 就对一个 请求封装好了。
      if (this.formdata.username == '') {
        this.loading = false
        this.$message({
          message: '用户名不能为空!',
          type: 'error',
          offset: 100,
        })
        return
      }
      if (this.formdata.password == '') {
        this.loading = false
        this.$message({
          message: '密码不能为空!',
          type: 'error',
          offset: 100,
        })
        return
      }
      let data = this.formdata
      this.$service
        .login(data)
        .then((res) => {
          console.log(res)
          localStorage.setItem('username', res.Username)
          setTimeout(
            function () {
              this.$message({
                message: '登陆成功!',
                type: 'success',
                offset: 100,
              })
              this.$router.push({
                path: '/index',
              })
            }.bind(this),
            1000
          )
          return
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
/* //主体 */
.login-mian {
  display: flex;
  flex-flow: column;
}

/* 头部 */
.login-mian-head {
  width: 1200px;
  margin: 0 auto;
  height: 7.0625rem;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

/* 头部logo */
.login-mian-head > img {
  width: 14.375rem;
}

/* 主体 */
.login-mian-body {
  height: 46.9375rem;
  display: flex;
  flex-flow: row;
  justify-content: center;
  background-image: linear-gradient(to right, #3e68ff, #31b3f9);
}

/* 主体--登录框 */
.body-form {
  width: 1200px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  height: 45rem;
  background-image: url(../../assets/images/login/bg.png);
  background-repeat: no-repeat;
}

.body-form-mian {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  height: 31.5rem;
  width: 28.25rem;
  background: #fff;
  border-radius: 4px;
}

.body-form-mian-item {
  display: flex;
  flex-flow: column nowrap;
  width: 19.5rem;
}

.body-form-mian-item > p {
  width: 11.375rem;
  height: 2.3125rem;
  font-size: 1.625rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 800;
  color: #333333;
  line-height: 2.3125rem;
  margin-top: 2.5625rem;
}

/* 样式穿透 */
::v-deep .el-input__inner {
  height: 2.875rem;
}

::v-deep .form-ipunt .el-input__inner {
  padding-left: 2.1875rem;
  color: #333;
  font-weight: normal;
}

/* //登录框按钮 */
.form-but {
  // height: 2.875rem;
  // background: linear-gradient(90deg, #1961ff 0%, #00bcff 100%);
  // border-radius: 0.125rem;
  // font-size: 1.125rem;
  // color: #fff;
  // display: flex;
  // flex-flow: row;
  // align-items: center;
  // justify-content: center;
  margin-top: 5.0625rem;
}
.footer {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  height: calc(100vh - 870px);
  line-height: 60px;
  font-size: 12px;
  text-align: center;
}
</style>
